// components/admin/BrandForm.js
import React, { useState, useEffect, useContext } from 'react';
import { BrandsContext } from '../../context/BrandsContext';
import useFields from '../../hooks/useFields';
import './BrandForm.css';

const BrandForm = ({ brand, onClose }) => {
  const {
    brands,
    loading: brandsLoading,
    error: brandsError,
    addBrand,
    updateBrand,
  } = useContext(BrandsContext);

  const {
    fields: availableFields,
    loading: fieldsLoading,
    error: fieldsError,
  } = useFields();

  const [formData, setFormData] = useState({
    name: '',
    logo_url: '',
    parent_id: '',
    template: '',
  });

  const [selectedFields, setSelectedFields] = useState([]);

  useEffect(() => {
    if (brand) {
      setFormData({
        name: brand.name || '',
        logo_url: brand.logo_url || '',
        parent_id: brand.parent_id ? brand.parent_id.toString() : '',
        template: brand.template || '',
      });

      // Map the brand's fields to include the order and sort them
      setSelectedFields(
        brand.fields
          .map((field) => ({
            ...field,
            order: field.BrandField.order,
          }))
          .sort((a, b) => a.order - b.order)
      );
    } else {
      // Reset form data when adding a new brand
      setFormData({
        name: '',
        logo_url: '',
        parent_id: '',
        template: '',
      });
      setSelectedFields([]);
    }
  }, [brand]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFieldSelection = (fieldId) => {
    setSelectedFields((prevFields) => {
      const fieldIndex = prevFields.findIndex((field) => field.id === fieldId);
      if (fieldIndex !== -1) {
        // Remove the field
        const updatedFields = [...prevFields];
        updatedFields.splice(fieldIndex, 1);
        return updatedFields;
      } else {
        // Add the field with default order at the end
        const field = availableFields.find((f) => f.id === fieldId);
        return [...prevFields, { ...field, order: prevFields.length + 1 }];
      }
    });
  };

  const handleFieldOrderChange = (fieldId, newOrder) => {
    setSelectedFields((prevFields) => {
      return prevFields.map((field) =>
        field.id === fieldId ? { ...field, order: parseInt(newOrder, 10) } : field
      );
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form data
    if (!formData.name.trim()) {
      alert('Please enter a brand name.');
      return;
    }

    const submitData = {
      name: formData.name,
      logo_url: formData.logo_url,
      parent_id: formData.parent_id ? parseInt(formData.parent_id, 10) : null,
      template: formData.template,
      fields: selectedFields.map((field) => ({
        id: field.id,
        order: field.order || 0,
      })),
    };

    const action = brand ? updateBrand(brand.id, submitData) : addBrand(submitData);

    action
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error('Error saving brand:', error);
        alert('An error occurred while saving the brand. Please try again.');
      });
  };

  if (brandsLoading || fieldsLoading) {
    return (
      <div className="brand-form-overlay">
        <div className="brand-form-container">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (brandsError || fieldsError) {
    return (
      <div className="brand-form-overlay">
        <div className="brand-form-container">
          <p>
            Error loading data:{' '}
            {brandsError ? brandsError.message : fieldsError.message}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="brand-form-overlay">
      <div className="brand-form-container">
        <h3>{brand ? 'Edit Brand' : 'Add Brand'}</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="brand-name">Name:</label>
            <input
              type="text"
              id="brand-name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="form-input"
              placeholder="Enter brand name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="logo-url">Logo URL:</label>
            <input
              type="text"
              id="logo-url"
              name="logo_url"
              value={formData.logo_url}
              onChange={handleChange}
              required
              className="form-input"
              placeholder="Enter logo URL"
            />
          </div>
          <div className="form-group">
            <label htmlFor="parent-brand">Parent Brand:</label>
            <select
              id="parent-brand"
              name="parent_id"
              value={formData.parent_id}
              onChange={handleChange}
              className="form-input"
            >
              <option value="">None</option>
              {brands
                .filter((b) => !brand || b.id !== brand.id)
                .map((b) => (
                  <option key={b.id} value={b.id}>
                    {b.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="template">Template:</label>
            <textarea
              id="template"
              name="template"
              value={formData.template}
              onChange={handleChange}
              className="form-input"
              rows={4}
              placeholder="Enter template (optional)"
            />
          </div>
          <div className="form-group">
            <label>Fields:</label>
            {availableFields.length === 0 ? (
              <p>No fields available.</p>
            ) : (
              <div className="fields-list">
                {availableFields.map((field) => {
                  const isSelected = selectedFields.some((sf) => sf.id === field.id);
                  const fieldOrder = isSelected
                    ? selectedFields.find((sf) => sf.id === field.id).order
                    : '';

                  return (
                    <div key={field.id} className="field-item">
                      <div className="field-item-checkbox">
                        <input
                          type="checkbox"
                          id={`field-${field.id}`}
                          checked={isSelected}
                          onChange={() => handleFieldSelection(field.id)}
                        />
                        <label htmlFor={`field-${field.id}`}>{field.label}</label>
                      </div>
                      {isSelected && (
                        <div className="field-item-order">
                          <label htmlFor={`field-order-${field.id}`}>Order:</label>
                          <input
                            type="number"
                            id={`field-order-${field.id}`}
                            min="1"
                            value={fieldOrder}
                            onChange={(e) =>
                              handleFieldOrderChange(field.id, e.target.value)
                            }
                            className="field-order-input"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="form-actions">
            <button type="submit" className="save-button">
              {brand ? 'Update' : 'Create'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BrandForm;