import React, { useContext } from 'react';
import { FaTrash, FaFileExport, FaPrint, FaImage } from 'react-icons/fa';
import useTranslations from '../hooks/useTranslations';
import { AppContext } from '../context/AppContext'; // Use context for images

const Optionbar = ({ onClear, onExport, onPrint }) => {
  const { t } = useTranslations();
  const { leftImage, rightImage, watermarkImage, setLeftImage, setRightImage, setWatermarkImage } = useContext(AppContext); // Use context for images

  // Function to handle file uploads
  const handleFileUpload = (event, side) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (side === 'left') {
          setLeftImage(reader.result);
        } else if (side === 'right') {
          setRightImage(reader.result);
        } else if (side === 'watermark') {
          setWatermarkImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="optionbar flex flex-col space-y-2 p-2">
      <button onClick={onClear} className="flex items-center space-x-2 p-2 text-gray-700 hover:text-black focus:outline-none">
        <FaTrash className="w-5 h-5" />
        <span>{t('CLEAR_SHEETS')}</span>
      </button>
      
      <button onClick={onExport} className="flex items-center space-x-2 p-2 text-gray-700 hover:text-black focus:outline-none">
        <FaFileExport className="w-5 h-5" />
        <span>{t('EXPORT_SHEETS')}</span>
      </button>
      
      <button onClick={onPrint} className="flex items-center space-x-2 p-2 text-gray-700 hover:text-black focus:outline-none">
        <FaPrint className="w-5 h-5" />
        <span>{t('PRINT_SHEETS')}</span>
      </button>

      <div className="image-upload-section flex space-x-4 justify-center items-center mt-4">
        {/* Left Bottom Image Upload */}
        <div className="image-upload relative w-24 h-24 border border-gray-300 rounded-md flex justify-center items-center">
          {leftImage ? (
            <img src={leftImage} alt="Left Preview" className="w-full h-full object-cover rounded-md" />
          ) : (
            <label htmlFor="left-image-upload" className="cursor-pointer text-gray-400 hover:text-gray-600">
              <FaImage className="w-8 h-8" />
              <input
                id="left-image-upload"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={(e) => handleFileUpload(e, 'left')}
              />
            </label>
          )}
        </div>

        {/* Right Bottom Image Upload */}
        <div className="image-upload relative w-24 h-24 border border-gray-300 rounded-md flex justify-center items-center">
          {rightImage ? (
            <img src={rightImage} alt="Right Preview" className="w-full h-full object-cover rounded-md" />
          ) : (
            <label htmlFor="right-image-upload" className="cursor-pointer text-gray-400 hover:text-gray-600">
              <FaImage className="w-8 h-8" />
              <input
                id="right-image-upload"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={(e) => handleFileUpload(e, 'right')}
              />
            </label>
          )}
        </div>
      </div>

      {/* Watermark Image Upload */}
      <div className="image-upload relative w-full h-48 border border-gray-300 rounded-md flex justify-center items-center mt-4">
        {watermarkImage ? (
          <img src={watermarkImage} alt="Watermark Preview" className="w-full h-full object-cover rounded-md" />
        ) : (
          <label 
            htmlFor="watermark-image-upload" 
            className="cursor-pointer text-gray-400 hover:text-gray-600 flex flex-col items-center justify-center"
          >
            <FaImage className="w-16 h-16" />
            <span className="block mt-2 text-center">{t('WATERMARK')}</span>
            <input
              id="watermark-image-upload"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={(e) => handleFileUpload(e, 'watermark')}
            />
          </label>
        )}
      </div>
    </div>
  );
};

export default Optionbar;