import React from 'react';
import TextInput from './inputs/TextInput';
import PinInput from './inputs/PinInput';
import PatternInput from './inputs/PatternInput';
import useTranslations from '../hooks/useTranslations';

const inputComponents = {
  text: TextInput,
  email: TextInput,
  phone: TextInput,
  password: TextInput,
  pin: PinInput,
  pattern: PatternInput,
};

const AccountSheet = ({ sheet, onInputChange, onRemove }) => {
  const { t } = useTranslations(); // Get the translation function
  const { brand, fields } = sheet;

  // Sort fields by order before rendering
  const sortedFields = fields.sort((a, b) => a.order - b.order);

  return (
    <div className="relative bg-white border border-gray-300 rounded-lg p-6 mb-6 shadow-lg z-10">
      {/* Remove Button */}
      <button
        onClick={() => onRemove(sheet.id)}
        className="absolute top-2 right-2 text-red-500 hover:text-red-700 text-xl font-bold"
      >
        &times;
      </button>
      {/* Account Sheet Content */}
      <div className="flex flex-col">
        {/* Translate brand.name only if it's a generic label */}
        <h3 className="text-xl font-semibold text-gray-800 mb-4">{t(brand.name)}</h3>
        <div className="flex items-center mb-6">
          <img
            src={brand.logo_url}
            alt={`${brand.name} Logo`}
            className="h-auto w-40 mr-6"
          />
          <div className="flex flex-col space-y-4 w-full">
            {sortedFields.map((field) => {
              const InputComponent = inputComponents[field.type];
              if (!InputComponent) return null; // Skip unknown types

              return (
                <InputComponent
                  key={field.name}
                  field={field}
                  value={sheet.data[field.name]}
                  onChange={(e) => onInputChange(e, sheet.id)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSheet;